import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './App.css';
import 'react-image-crop/dist/ReactCrop.css';
import { getFormatHost } from '@appypay/common/utils/format';
import { productionHosts } from '@appypay/common/utils/constants';
import LocalStorage from '@appypay/common/utils/localStorage';
import { mixPanelInit } from '@appypay/common/utils/mixpanelEvent';
import i18n from 'localization';
import { Helmet } from 'react-helmet';
import authentication from './auth';
import App from './App';

(async () => {
  const authConfig = await (
    await fetch(`${process.env.PUBLIC_URL}/assets/config.json`)
  ).json();

  window.appyConfig = {
    ...authConfig,
    AGGREGATOR: authConfig?.AGGREGATOR || 'appy',
  };

  const isProduction =
    getFormatHost() === productionHosts.landing ||
    getFormatHost() === productionHosts.portal;

  const isLanding =
    getFormatHost() === productionHosts.landing ||
    window.location.pathname === '/';

  const logOutUrl = () => {
    if (isProduction && isLanding) {
      return `${window.location.protocol}//${productionHosts.portal}`;
    }

    if (isProduction && !isLanding) {
      return `${window.location.protocol}//${productionHosts.landing}`;
    }

    if (isLanding) {
      return `${window.location.origin}/portal`;
    }

    return window.location.origin;
  };

  authentication.initialize({
    instance: authConfig.ADB2C_INSTANCE,
    tenant: authConfig.ADB2C_TENANT,
    signInPolicy:
      LocalStorage.get('APPY_B2C_POLICY') || authConfig.ADB2C_SIGNIN_POLICY,
    applicationId: authConfig.ADB2C_APPLICATION_ID,
    cacheLocation: authConfig.ADB2C_CACHE_LOCATION,
    scopes: authConfig.ADB2C_SCOPES.split(','),
    resetPolicy: authConfig.ADB2C_RESET_POLICY,
    redirectUri: window.location.origin,
    validateAuthority: false,
    postLogoutRedirectUri: logOutUrl(),
    silentLoginOnly: false,
    language: LocalStorage.get('lang') || i18n.language,
  });

  mixPanelInit(authConfig.MIXPANEL_KEY);

  ReactDOM.render(
    // TODO: return StrictMode when project will done
    <>
      <Helmet>
        <link
          href={`${window.location.origin}/assets/aggregators/${
            authConfig?.AGGREGATOR || 'appy'
          }/images/favicon.ico`}
          id="favicon"
          rel="icon"
        />
      </Helmet>
      <App />
    </>,
    document.getElementById('root')
  );
})();
