import { ErrorBoundary } from '@appypay/common/ui/ErrorBoundary';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getAggregatorPalette } from '@appypay/common/utils/themeProviderHelper';
import i18next from 'i18next';
import Routers from './routers';
import i18n from './localization';

function App() {
  const [aggregatorDetails, setAggregatorDetails] = useState(null);
  const [locale, setLocale] = useState(i18n.language);

  i18next.on('languageChanged', (e) => {
    setLocale(e);
  });

  useEffect(async () => {
    const appyThemeConfig = await getAggregatorPalette();
    setAggregatorDetails(appyThemeConfig);
    window.appyThemeConfig = appyThemeConfig;
    window.zESettings = {
      webWidget: {
        color: {
          theme: appyThemeConfig?.palette?.primary,
        },
      },
    };
  }, []);

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{aggregatorDetails?.title?.[locale || 'en']}</title>
      </Helmet>
      <Routers />
    </ErrorBoundary>
  );
}

export default App;
