import React from 'react';
import { getAggregatorImage } from '../../utils/ImageSrcHelper';

function Loading() {
  return (
    <div className="main-loader">
      <img
        style={{ maxWidth: '200px' }}
        src={getAggregatorImage('loading.gif')}
        alt="Loading..."
      />
    </div>
  );
}

export default Loading;
