export const primary = '#2bb0cf';
export const placeholderColor = '#b9b9b9';
export const white = '#fff';
export const black = '#444';
export const green = '#4ce1b6';
export const red = '#ff8282';
export const secondary = '#ff8282';
export const yellow = '#f6da6e';
export const blue = '#70bbfd';
export const dimBlue = '#acd0d8';
export const lightBlue = '#e4f5f9';
export const lightBlue1 = '#f1fafc';
export const grey = '#999';
export const darkGrey = '#666';
export const lightGrey = '#fafafa';
export const dimGrey = '#f5f5f5';
export const lightRed = '#f8d7da';
export const dimRed = '#f5c6cb';
