import React from 'react';

function logErrorToMyService(error, errorInfo) {
  console.error('Error: ', error);
  console.error('ErrorInfo: ', errorInfo);
}

// eslint-disable-next-line import/prefer-default-export
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logErrorToMyService(error, errorInfo);
  }

  render() {
    const {
      state: { hasError },
      props: { children },
    } = this;

    if (hasError) {
      return (
        <center>
          <h1>Something went wrong.</h1>
        </center>
      );
    }

    return children;
  }
}
