import { isValidUrl } from './validator';

export const getAggregatorImage = (imageType) =>
  `${window.location.origin}/assets/aggregators/${
    window.appyConfig.AGGREGATOR || 'appy'
  }/images/${imageType}`;

const getImageSrc = (src) =>
  isValidUrl(src) ? src : `data:image/png;base64,${src}`;

export default getImageSrc;
