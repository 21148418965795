import { adaptV4Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material';
import { white } from '../ui/Theme/colors';

export const getColorShade = (hex, lum) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#';
  let c;
  let i;
  for (i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }
  return rgb;
};

const type = 'light';

export const createMUITheme = (theme) =>
  createTheme(
    adaptV4Theme({
      palette: {
        mode: theme.palette.mode,
        primary: {
          main: theme.palette.primary.main,
          light: theme.palette.primary.light,
          dark: theme.palette.primary.dark,
        },
        secondary: {
          main: theme.palette.secondary.main,
          light: theme.palette.secondary.light,
          dark: theme.palette.secondary.dark,
        },
        background: {
          default: theme.palette.background.default,
          paper: theme.palette.background.paper,
          light: theme.palette.background.light,
        },
        text: {
          primary: theme.palette.text.primary,
          secondary: theme.palette.text.secondary,
          disabled: theme.palette.text.disabled,
          dark: theme.palette.background.paper,
        },
        success: {
          main: theme.palette.success.main,
        },
        error: {
          main: theme.palette.error.main,
          light: theme.palette.error.light,
          dark: theme.palette.error.dark,
        },
        warning: {
          main: theme.palette.warning.main,
          light: theme.palette.warning.light,
        },
      },
      typography: {
        // htmlFontSize: theme.typography.htmlFontSize,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        button: {
          fontWeight: 500,
        },
      },
      spacing: 8,
      breakpoints: {
        values: {
          xs: 0,
          sm: 641,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '*::-webkit-scrollbar': {
              width: '10px',
            },
            '*::-webkit-scrollbar-track': {
              background: theme.palette.background.default,
            },
            '*::-webkit-scrollbar-thumb': {
              background: '#A9A9A9',
            },
            '.Mui-disabled': {
              cursor: 'not-allowed !important',
              backgroundColor: 'transparent !important',
              color: 'rgba(0, 0, 0, 0.26) !important',
              // '&> *': {
              //   cursor: 'not-allowed !important',
              //   backgroundColor: 'transparent !important',
              //   color: 'rgba(0, 0, 0, 0.26) !important',
              // },
            },
          },
          styleOverrides: `
        @font-face {
          font-family: ${theme.typography.fontFamily};
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${theme.typography.fontFile}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
        },
        MuiTypography: {
          root: {
            color: theme.palette.text.primary,
          },
        },
        MuiAutocomplete: {
          clearIndicator: {
            color: theme.palette.text.primary,
          },
          popupIndicator: {
            color: theme.palette.text.primary,
          },
        },
        MuiChip: {
          root: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          },
          deleteIcon: { color: theme.palette.text.primary },
        },
        MuiButton: {
          root: {
            borderRadius: 22,
            padding: '8px 16px',
            fontSize: 14,
            fontWeight: 600,
            minWidth: 195,
            textTransform: 'none',
          },
          contained: {
            boxShadow: 'initial',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
            '&.Mui-disabled': {
              cursor: 'not-allowed !important',
              color: 'rgba(0, 0, 0, 0.26) !important',
              pointerEvents: 'unset',
            },
          },
          containedPrimary: {
            color: white,
          },
          containedSecondary: {
            backgroundColor: theme.palette.secondary.main,
            color: white,
          },
          disabled: {
            cursor: 'not-allowed !important',
            color: 'rgba(0, 0, 0, 0.26) !important',
            pointerEvents: 'unset',
          },
        },
        MuiDialogTitle: {
          root: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          },
        },
        MuiDialogContent: {
          root: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          },
        },
        MuiTablePagination: {
          root: {
            color: theme.palette.text.primary,
          },
          actions: {
            color: theme.palette.text.primary,
          },
        },
        MuiTableCell: {
          root: {
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            paddingLeft: 5,
            paddingRight: 5,
            '&:first-child': {
              paddingLeft: 16,
            },
          },
          head: {
            fontWeight: 'bold',
          },
        },
        MuiMenu: {
          list: {
            paddingTop: 0,
            paddingBottom: 0,
            '& li': {
              fontSize: 14,
            },
            '& li:hover': {
              backgroundColor: theme.palette.background.light,
            },
            '& li:focus': {
              backgroundColor: theme.palette.background.light,
            },
            '& li.Mui-selected': {
              fontWeight: 600,
              backgroundColor: theme.palette.background.light,
            },
          },
        },
        MuiSelect: {
          select: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px 10px 5px 8px',
            border: '1px solid transparent',
            borderRadius: 21,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
            fontSize: 14,
            '&:focus': {
              borderRadius: 21,
              backgroundColor: theme.palette.background.light,
            },
            '&:hover': {
              borderRadius: 21,
              backgroundColor: theme.palette.background.light,
            },
            '&.Mui-disabled': {
              cursor: 'not-allowed !important',
              color: 'rgba(0, 0, 0, 0.26) !important',
              pointerEvents: 'unset',
            },
          },
          icon: {
            color: theme.palette.text.primary,
            '&.Mui-disabled': {
              cursor: 'not-allowed !important',
              color: 'rgba(0, 0, 0, 0.26) !important',
              pointerEvents: 'unset',
            },
          },
          selectMenu: {
            minHeight: '22px',
          },
        },
        MuiInputBase: {
          root: {
            '& input::placeholder': {
              color: theme.palette.text.primary,
              opacity: 1,
            },
            '&.Mui-disabled': {
              cursor: 'not-allowed !important',
              color: 'rgba(0, 0, 0, 0.26) !important',
              pointerEvents: 'unset',
            },
          },
        },
        MuiButtonBase: {
          root: {
            '&.Mui-disabled': {
              cursor: 'not-allowed !important',
              color: 'rgba(0, 0, 0, 0.26) !important',
              pointerEvents: 'unset',
            },
          },
        },
        MuiIconButton: {
          root: {
            '&.Mui-disabled': {
              cursor: 'not-allowed !important',
              color: 'rgba(0, 0, 0, 0.26) !important',
              pointerEvents: 'unset',
            },
          },
        },
        MuiSwitch: {
          root: {
            '&.Mui-disabled': {
              cursor: 'not-allowed !important',
              backgroundColor: 'transparent !important',
              color: 'rgba(0, 0, 0, 0.26) !important',
              '&> *': {
                cursor: 'not-allowed !important',
                backgroundColor: 'transparent !important',
                color: 'rgba(0, 0, 0, 0.26) !important',
              },
            },
          },
        },
      },
    })
  );

export const getAggregatorPalette = async () => {
  const aggregatorPalette = await fetch(
    `${window.location.origin}/assets/aggregators/${window.appyConfig.AGGREGATOR}/themeConfig.json`
  );
  return aggregatorPalette.json();
};

export const getThemeObject = ({ fontFile, fontFamily, palette }) => {
  const { warning, error, success, text, background, secondary, primary } =
    palette ?? {};
  return {
    palette: {
      mode: type,
      primary: {
        main: primary,
        light: getColorShade(primary, 0.3),
        dark: getColorShade(primary, type === 'light' ? -0.3 : 0.2),
      },
      secondary: {
        main: secondary,
        light: getColorShade(secondary, 0.3),
        dark: getColorShade(secondary, -0.3),
      },
      background: {
        default: background,
        paper: getColorShade(background, 0.3),
        light: getColorShade(background, type === 'light' ? -0.1 : 0.2),
        dark: getColorShade(background, -0.6),
      },
      text: {
        primary: text,
        secondary: getColorShade(text, 0.3),
        disabled: getColorShade(text, 0.6),
      },
      success: {
        main: success,
        light: getColorShade(success, 0.6),
        dark: getColorShade(success, -0.3),
      },
      error: {
        main: error,
        light: getColorShade(error, 1.5),
        dark: getColorShade(error, -0.3),
      },
      warning: {
        main: warning,
        light: getColorShade(warning, 1.5),
        dark: getColorShade(warning, -0.3),
      },
    },
    typography: {
      // htmlFontSize: 14,
      fontFile,
      fontFamily,
      fontSize: 14,
      button: {
        fontWeight: 500,
      },
    },
  };
};
