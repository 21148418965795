import {
  blue,
  darkGrey,
  dimRed,
  green,
  grey,
  primary,
  red,
  yellow,
} from '../ui/Theme/colors';

export const productionHosts = {
  portal: 'portal.appypay.co.ao',
  landing: 'appypay.co.ao',
};

export const paymentTypes = [
  { value: 'all', label: 'type' },
  { value: 'ONE_TIME_PAYMENT', label: 'oneTime' },
  { value: 'TWO_TIME_PAYMENT', label: 'twoTime' },
];

export const statusList = [
  { value: 'all', label: 'statuses.selectStatus' },
  { value: 'NONE', label: 'statuses.none' },
  { value: 'PENDING', label: 'statuses.pending' },
  { value: 'SUCCESS', label: 'statuses.success' },
  { value: 'FAILED', label: 'statuses.failed' },
  { value: 'CANCELLED', label: 'statuses.cancelled' },
  { value: 'UNKNOWN', label: 'statuses.unknown' },
];

export const typesList = [
  { value: 'all', label: 'types.placeholder' },
  { value: 'Authorization', label: 'types.authorization' },
  { value: 'Intents', label: 'types.intends' },
  { value: 'Charge', label: 'types.charge' },
];

export const statusesActive = [
  { value: 'all', label: 'statuses.selectStatus' },
  { value: true, label: 'Active' },
  { value: false, label: 'Not Active' },
];

export const paymentMethodList = [
  { value: '', label: 'paymentMethod' },
  { value: 'Online Payment', label: 'onlinePayment' },
  { value: 'Direct Debit', label: 'directDebit' },
];

export const statusColor = {
  red: [
    'rejected',
    'cancelled',
    'refused',
    'no funds',
    'revoked',
    'reversed',
    'failed',
    'not active',
    'canc',
    'expi',
    'recs',
  ],
  yellow: [
    'pending approval',
    'charging',
    'pending',
    'registered',
    'rgst',
    'revt',
  ],
  blue: ['submitted', 'new', 'aprt'],
  green: ['charged', 'success', 'active', 'actv', 'debt', 'dvrv', 'chrged'],
  blueStripes: ['sent'],
};

export const typesOfTransactions = {
  AUTHORIZATION: 'Authorization',
  INTENTS: 'Intents',
  CHARGE: 'Charge',
};

export const statusesOfTransactions = {
  NEW: 'New',
  PENDING: 'Pending',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  CANCELED: 'Canceled',
};

export const applicationsGroupOfStatuses = {
  ALL: {
    label: 'All',
    statuses: '',
  },
  ACTIVE: {
    label: 'operating',
    statuses: 'Active',
  },
  NOT_ACTIVE: {
    label: 'inactive',
    statuses: 'Not Active',
  },
};

export const usersGroupOfStatuses = {
  ALL: {
    label: 'All',
    statuses: '',
  },
  ACTIVE: {
    label: 'operating',
    statuses: 'Active',
  },
  NOT_ACTIVE: {
    label: 'inactive',
    statuses: 'Not Active',
  },
};

export const mandateGroupOfStatuses = {
  ALL: {
    label: 'all',
    statuses: '',
  },
  ACTIVE: {
    label: 'operating',
    statuses: 'ACTV,RGST',
  },
  NOT_ACTIVE: {
    label: 'inactive',
    statuses: 'SENT,EXPI,CANC,CANCLNG,DECL',
  },
};

export const mandatesStatusesLabels = {
  ACTV: 'statuses.active',
  RGST: 'statuses.registered',
  SENT: 'statuses.sent',
  EXPI: 'statuses.expired',
  CANC: 'statuses.cancelled',
  DECL: 'statuses.declined',
  CANCLNG: 'statuses.cancelling',
  EXPIRING: 'statuses.expiring',
};

export const mandateStatusesList = [
  { value: 'all', label: 'statuses.selectStatus' },
  { value: 'ACTV', label: 'statuses.active' },
  { value: 'RGST', label: 'statuses.registered' },
  { value: 'SENT', label: 'statuses.sent' },
  { value: 'EXPI', label: 'statuses.expired' },
  { value: 'EXPIRING', label: 'statuses.expiring' },
  { value: 'CANC', label: 'statuses.cancelled' },
  { value: 'DECL', label: 'statuses.declined' },
  { value: 'CANCLNG', label: 'statuses.cancelling' },
];

export const intentsGroupOfStatuses = {
  ALL: {
    label: 'all',
    statuses: '',
  },
  ACTIVE: {
    label: 'operating',
    statuses: 'REVTING,CANCLNG,DEBT,CHRGING,APRT,SENT',
  },
  NOT_ACTIVE: {
    label: 'inactive',
    statuses: 'REVT,CANC,DECL',
  },
};

export const intentsStatusesLabels = {
  DRAF: 'statuses.draft',
  APRT: 'statuses.presented',
  CANC: 'statuses.cancelled',
  DVRV: 'statuses.withdrawal',
  REVT: 'statuses.reverted',
  REVTING: 'statuses.reverting',
  DECL: 'statuses.declined',
  CANCLNG: 'statuses.cancelling',
  SENT: 'statuses.sent',
  CHRGED: 'statuses.charged',
  DEBT: 'statuses.debt',
  CHRGING: 'statuses.charging',
};

export const intentsStatusesList = [
  { value: 'all', label: 'statuses.selectStatus' },
  { value: 'SENT', label: 'statuses.sent' },
  { value: 'APRT', label: 'statuses.presented' },
  { value: 'CHRGING', label: 'statuses.charging' },
  { value: 'DEBT', label: 'statuses.debt' },
  { value: 'DECL', label: 'statuses.declined' },
  { value: 'CANCLNG', label: 'statuses.cancelling' },
  { value: 'CANC', label: 'statuses.cancelled' },
  { value: 'REVTING', label: 'statuses.reverting' },
  { value: 'REVT', label: 'statuses.reverted' },
  { value: 'DVRV', label: 'statuses.withdrawal' },
];

export const paymentMethod = [
  { value: 'all', label: 'method' },
  { value: 'VISA', label: 'VISA' },
  { value: 'MC', label: 'Master Card' },
];

export const dashboardChartPeriods = [
  {
    label: 'ranges.today',
    value: 'TODAY',
  },
  {
    label: 'ranges.yesterday',
    value: 'YESTERDAY',
  },
  {
    label: 'ranges.last7days',
    value: 'LAST7DAYS',
  },
  {
    label: 'ranges.last28days',
    value: 'LAST28DAYS',
  },
];

export const billingColors = [
  green,
  red,
  primary,
  yellow,
  darkGrey,
  dimRed,
  blue,
];

export const chartColors = [
  '#bfe7f1',
  '#6ac8dd',
  '#3ab0cf',
  '#0187a2',
  '#055363',
];

export const statusColors = {
  canceled: primary,
  failed: red,
  successful: green,
  unknown: grey,
};

export const itemsPerPage = [5, 10, 25];

export const minRows = 5;

export const onlinePaymentTypes = [
  { value: 1, label: 'oneTime' },
  { value: 0, label: 'twoTime' },
];

export const paymentsType = {
  1: 'applicationsPage.directDebit',
  2: 'applicationsPage.multicaixaExpress',
};

export const frequencyList = {
  ADHO: 'frequencyTypes.adHoc',
  DAIL: 'frequencyTypes.daily',
  MIAN: 'frequencyTypes.semesterly',
  MNTH: 'frequencyTypes.monthly',
  QURT: 'frequencyTypes.trimestral',
  WEEK: 'frequencyTypes.weekly',
  YEAR: 'frequencyTypes.annually',
};

export const purposeCategoryList = {
  CASH: 'purposeCategory.payments',
  GOVT: 'purposeCategory.govern',
  OTRF: 'purposeCategory.other',
  PENS: 'purposeCategory.pension',
  SALA: 'purposeCategory.paychecks',
  SSBE: 'purposeCategory.social',
  SUPP: 'purposeCategory.providers',
  TRAD: 'purposeCategory.commodity',
};

export const mandatePurposeList = {
  CMDT: 'mandatePurpose.commodityPayments',
  CCRD: 'mandatePurpose.creditCardPayment',
  GDDS: 'mandatePurpose.goodsPayments',
  ENRG: 'mandatePurpose.electricPower',
  SCVE: 'mandatePurpose.servicePayments',
  SUPP: 'mandatePurpose.providerPayment',
  TRAD: 'mandatePurpose.purchaseAndSales',
  NWCH: 'mandatePurpose.communicationPayments',
  OTLC: 'mandatePurpose.otherCommunications',
  RINP: 'mandatePurpose.renderPayments',
  GOVT: 'mandatePurpose.paymentsRefunds',
  MSCV: 'mandatePurpose.services',
  NOWS: 'mandatePurpose.nonSpecial',
  RCPT: 'mandatePurpose.receiptPayment',
  ALMY: 'mandatePurpose.familyAllowance',
  BECH: 'mandatePurpose.alimony',
  BENE: 'mandatePurpose.unemploymentAllowance',
  SALA: 'mandatePurpose.paycheck',
  SSBE: 'mandatePurpose.socialSecurity',
  PENS: 'mandatePurpose.pension',
  TAXS: 'mandatePurpose.taxRefunds',
  AIRB: 'mandatePurpose.airTransportation',
  FERB: 'mandatePurpose.seaTransportation',
  OTHR: 'mandatePurpose.other',
  UTIL: 'mandatePurpose.utilities',
  HSPC: 'mandatePurpose.hospitalCare',
  MDCS: 'mandatePurpose.medicalService',
};

export const mandatesCancellationReasons = {
  BEDB: 'mandatesCancellationReasons.BEDB',
  CTAM: 'mandatesCancellationReasons.CTAM',
  CTCA: 'mandatesCancellationReasons.CTCA',
  CTEX: 'mandatesCancellationReasons.CTEX',
  CUST: 'mandatesCancellationReasons.CUST',
  DS24: 'mandatesCancellationReasons.DS24',
  DS99: 'mandatesCancellationReasons.DS99',
  DUPL: 'mandatesCancellationReasons.DUPL',
  MCFC: 'mandatesCancellationReasons.MCFC',
  MCOC: 'mandatesCancellationReasons.MCOC',
  MSUC: 'mandatesCancellationReasons.MSUC',
  RFED: 'mandatesCancellationReasons.RFED',
  RFEC: 'mandatesCancellationReasons.RFEC',
};

export const PERMISSION = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  CANCEL: 'CANCEL',
  VIEW_PII: 'VIEW-PII',
  REFUND: 'REFUND',
};

export const USER_ROLES = [
  { roleId: 2, name: 'Admin SDD' },
  { roleId: 3, name: 'Support L1 SDD' },
  { roleId: 4, name: 'Agente SDD' },
  { roleId: 8, name: 'Viewer SDD' },
];
